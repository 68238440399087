.category-item{
	position: relative;
	-webkit-transition: all .25s ease-in-out;
	   -moz-transition: all .25s ease-in-out;
	    -ms-transition: all .25s ease-in-out;
	     -o-transition: all .25s ease-in-out;
	        transition: all .25s ease-in-out;

	.content{
		position: absolute;
		content:"";
		left: 0px;
		right:0px;
		background: $white;
		padding: 25px 30px;
		bottom:-60px;
		text-align: center;
		width: 90%;
		margin: 0 auto;
		-webkit-transition: all .25s ease-in-out;
		   -moz-transition: all .25s ease-in-out;
		    -ms-transition: all .25s ease-in-out;
		     -o-transition: all .25s ease-in-out;
		        transition: all .25s ease-in-out;
	}

	.category-img{
		overflow: hidden;
		position: relative;
        img{
        	-webkit-transition: all .25s ease-in-out;
			   -moz-transition: all .25s ease-in-out;
			    -ms-transition: all .25s ease-in-out;
			     -o-transition: all .25s ease-in-out;
			        transition: all .25s ease-in-out;
        }
	}

	.category-img:hover img{

		-webkit-transform:translateY(-20px);
		   -moz-transform:translateY(-20px);
		    -ms-transform: translateY(-20px);
		     -o-transform:translateY(-20px);
		        transform: translateY(-20px);
	}
}

.post-list{
	.cat-name{
		color: $primary-color;
	}

	.post-view{
		position: relative;
		padding-left: 10px;
		margin-left: 10px;

		&:before{
			position: absolute;
			content:"";
			left: -10px;
			top:13px;
			width: 10px;
			height:1px;
			background: #000;
		}
	}
}

.post-article{
	padding-left: 40px;
}

// Post style 5
.label-date{
	.day{
		font-weight: 700;
		display: block;
	}
}

.label-date span.month {
    font-size: 11px;
    line-height: 1em;
}

.label-date span.day {
    font-size: 20px;
    line-height: 1em;
    font-weight: 700;
    margin-bottom: 7px;
}
.label-date span {
    display: block;
}

.label-date {
    width: 64px;
    border: 2px solid #eee;
    background-color:$white;
    padding: 12px 5px;
    text-align: center;
    z-index: 9;
    position: absolute;
    margin-top: -80px;
    
}


.post-content-grid{
	padding: 20px;
	p{
		font-size: 14px;
		margin-top: 15px;
	}
}

// Subscribe Home form
.subscribe-home{
	.form-control{
		background: $white;
	}
}

//  Post Quote
.post-quote{
	padding: 60px 40px;
	position: relative;
	z-index: 1;

	&:before{
		position: absolute;
		content:"";
		left: 0px;
		top:0px;
		width: 100%;
		height:100%;
		background: rgba(0,0,0,0.8);
		z-index: -1;
	}

	h3{
		color: $white;
	}

	p{
		color: rgba(255,255,255,.7);
	}
}

//  Post gallery
.post_gallery.owl-theme .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -15px;
}
.post_gallery.owl-theme .owl-nav .owl-prev {
    width: 35px;
	height: 53px;
	line-height: 15px;
	font-size: 20px;
	float: left;
    background: $primary-color;
    color: $white;
}

.post_gallery.owl-theme .owl-nav .owl-next{
   	float: right;
   	background: $primary-color;
   	color: $white;
	width: 35px;
	height: 53px;
	line-height: 15px;
	font-size: 20px;
}

// Video Post
.play-btn {
	line-height: 45px;
	position: absolute;
	top: 33%;
	left: 0px;
	width: 120px;
	height: 120px;
	text-align: center;
	border-radius: 50%;
	right: 0px;
	margin: 0 auto;
	font-size: 37px;
	padding-top: 38px;
    background:$primary-color;
    color: $white;

    &:hover{
    	background:$white;
    	color: $primary-color;
    }
}

@include mobile{
	.category-item .content {
	    position: relative;
	    bottom: 0px;
	}
	.play-btn{
		width: 90px;
		height:90px;
		font-size: 30px;
		padding-top: 24px;
	}
}

@include mobile-xs{
	.category-item .content {
	    position: relative;
	    bottom: 0px;
	}
	.play-btn{
		width: 90px;
		height:90px;
		font-size: 30px;
		padding-top: 24px;
	}
}

@include tablet{
	.category-item .content {
	    position: relative;
	    bottom: 0px;
	}
}