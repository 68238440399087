$primary-color:#CCC5B9;
// $primary-color:#ce8460;
$secondary-color: #403D39;
$success: #403D39;
$white: #FFFCF2;
$black: #252422;
$alert:#EB5E28;
$border-color:#dedede;
$bg-grey:$primary-color;
$primary-font: 'Unna', serif;
$secondary-font:'Open Sans', sans-serif;
$extra-font:'Lora', serif;

$text-color: #292931;
$text-color-dark: #1c1c1c;
$text-color-light: #a3a3a3;

$border-radius-base: 4px;
$bg-secondary:#f0f1f4;
