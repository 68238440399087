
/*-------------------------------
footer
-------------------------------*/
.footer-section {
  background: #f8f8f8;
  background:$black;
  padding: 60px 0px 0px;
  margin-top: 150px;
  position: relative;
}

.footer-section .copyright {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  color: rgba(255, 255, 255, 0.7);
}
.copyright{
  font-size: 14px;
  padding-top: 20px;
}

.footer-socials li,.footer-socials-2 li {
  margin: 0px 25px;
}

.footer-socials li a,.footer-socials-2 li a {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;

  &:hover{
    color: $primary-color;
  }
}

.footer-socials li a{
  color: $white;
  opacity: .8;

}
.footer-socials-2 li a {
  color:$black;
  opacity: .8;

}
.footer-socials li a i, .footer-socials-2 li a i{
  color: #777;
}

.footer-logo {
  color: $white;
}

//  instagram 
.instagram-photo-section {
  padding-bottom: 50px;
  margin-top: -250px;
}

.instagram-photo-section h4 {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 25px;
}

.instagram-photo-section img {
  border: 5px solid $white;
}

// Subscribe Footer
.subscribe-footer{
  .form-control{
    background: $white;
  }

  .btn{
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

//  Footer -six
.footer-home{
   padding-left: 320px;
   position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  z-index: 100;
  padding-right: 30px;
}


@include mobile{
  .subscribe-footer{
    .btn{
      margin-top: 10px;
    }
  }

  .footer-home {
    padding-left:0px;
  }
}
@include mobile-xs{
  .subscribe-footer{
    .btn{
      margin-top: 10px;
    }
  }

  .footer-home {
    padding-left:0px;
  }
}


@include tablet{
  .footer-home {
    padding-left:0px;
  }
}


@include desktop{
  .footer-home {
    padding-left:0px;
  }
}

