
// Navigation


.navigation {
  .menu {
    padding:0px;

    li {
      text-align: left;
      padding: 0px 10px;
      a {
        color:$black;
        display:inline-block;
        line-height: 26px;
        letter-spacing: 1px;
        
      }
    }

    .dropdown-toggle::after{
      display: none;
    }

   .dropdown{
      position: relative;
    }



    .dropdown-menu  {
        margin-left: 0px;
        transition: all 500ms cubic-bezier(.43, .26, .11, .99);
        -webkit-transition:all 500ms cubic-bezier(.43, .26, .11, .99);
        -o-transition:all 500ms cubic-bezier(.43, .26, .11, .99);
        -ms-transition: all 500ms cubic-bezier(.43, .26, .11, .99);
        width: 250px;
        height:auto;
        padding: 0px;
        border-radius: 0px;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        border: 2px solid rgba(0, 0, 0, 0.03);
        color:$white;
        background-color: $black;
        -webkit-transform: translateY(0px);
           -moz-transform: translateY(0px);
            -ms-transform: translateY(0px);
             -o-transform: translateY(0px);
                transform: translateY(0px);
        a{
          font-weight: 400;
          color:$white;
          background:$black;
          padding:15px;
          min-width: 210px;
          line-height:20px;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;
          padding: 12px 20px;

          &:hover{
            background:#111;
            color: $white;
            padding-left: 20px;
          }
        }
    }



  .dropdown:hover .dropdown-menu {
      visibility: visible;
      opacity: 1;
      display: block;
       -webkit-transform: translateY(-10px);
           -moz-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
             -o-transform: translateY(-10px);
                transform: translateY(-10px);
    }

  
  }
}


.dropdown-toggle::after {
  display: none;
}


.navbar-nav .nav-link{
   transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  font-family: $secondary-font;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 25px 0px;

  &:hover{
   color: $primary-color!important;
  }
}


.header-socials{
  a{
    font-size: 12px;
    padding: 0px 5px;
  }
}


.bg-dark{
  background: #111111!important;
}

.navigation-dark{
  .navigation .navbar-nav .nav-link{
    color: $white;
    font-weight: 400;
    font-size: 14px;
  }


  .header-socials-2{
    a{
      color:rgba(255,255,255,0.7);
    }
  }
}

.navigation.menu-white .navbar-nav .nav-link{
  color: $white;
 }

// Navigation-2
.header-socials-2{
  a{
    padding: 0px 5px;
    font-size: 14px;
    color:rgba(0,0,0,0.7);
   
  }
}

.search {
  a{
    color: $black;
    font-size: 14px;
    color:rgba(0,0,0,0.7);
  }
}

/*-------------------------------
overlay search
-------------------------------*/
.search-wrap {
  position: relative;
  z-index: 1200;
  display: inline-block;
  float: right;
  top: 20px;
  right: 16px;
}

.toggle-wrap {
  position: relative;
}

.search_toggle {
  cursor: pointer;
  color:$white;
}

.toggle-wrap.active {
  position: relative;
  top: 0%;
  right: 0%;
  cursor: pointer;
  z-index: 10000;
  transition: opacity .25s ease;
}

.toggle-wrap.active img {
  display: none;
}

.toggle-wrap img.search-close {
  display: none;
}

.toggle-wrap.active img.search-close {
  display: block;
}

.overlay {
  position: fixed;
  background: #1f1f1f;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .4s;
  overflow: hidden;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.overlay.open .col-md-3 {
  opacity: 1;
  -webkit-transform: translate3d(-100px, 0, 0);
  transform: translateY(-100px);
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}

.overlay.open .col-md-3:first-child {
  transition-delay: 0.4s;
}

.overlay.open .col-md-3:nth-child(2) {
  transition-delay: 0.45s;
}

.overlay.open .col-md-3:nth-child(3) {
  transition-delay: 0.5s;
}

.overlay.open .col-md-3:nth-child(4) {
  transition-delay: 0.55s;
}

.overlay .col-md-3 {
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.overlay .search-form {
  position: relative;
  height: 300px;
  padding-top: 120px;
  background: #222222;
}

.overlay .search-form input {
  background: transparent;
  border: none;
  font-size: 56px;
  height: 60px;
  color: #fff;
}

.overlay .search-form input:focus {
  box-shadow: none;
}

.overlay nav,
.overlay .search-blog-post {
  position: relative;
  height: 60%;
  top: 55%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.5rem;
}

.overlay .search-blog-post .post-header h3 {
  font-size: 16px;
  line-height: 26px;
}

.overlay .search-blog-post .post-header h3 a {
  color: #fff;
}

.overlay .search-blog-post .post-header h3 a:hover {
  color: #cba866;
}

.overlay .search-blog-post .post-meta {
  font-size: 14px;
}

.search-blog-title {
  position: relative;
  top: 3.5rem;
}

@media (max-width: 1366px) {
  .toggle-wrap.active {
    right: 10%;
  }
}

@media (max-width: 1024px) {
  .overlay .search-form {
    height: 200px;
    padding-top: 70px;
  }
  .toggle-wrap.active {
    top: 8%;
  }
}

@media (max-width: 768px) {
  .overlay nav, .overlay .search-blog-post {
    top: 50%;
  }
  .toggle-wrap.active {
    top: 7%;
  }
}

@media (max-width: 767px) {
  .overlay nav, .overlay .search-blog-post, .search-blog-title {
    display: none;
  }
  .overlay .search-form {
    height: 100%;
    padding-top: 50%;
  }
  .toggle-wrap.active {
    top: 15%;
    right: -10px;
  }
  .overlay .search-form input {
    font-size: 24px;
  }
  .toggle-wrap.active img.search-close {
    width: 70%;
  }
}



//  Home-6

.sidebar{
  position: relative;
  overflow: hidden;
  width: 100%;
  height:100%;
}

.content{
  width: 85%;
  margin: 0 auto;
  padding-left: 170px;
}
.sidebar-sticky {
    position: fixed;
    left: 0px;
    top:0px;
    background-color:$white;
    padding-left:30px;
    z-index: 99999;
    height:100%;
    width: 14%;
    border-right:2px solid #eee;
}

.main-menu{
  a {
    color:$black;
    display:inline-block;
    line-height: 26px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding-left: 0px;
    letter-spacing: 1px;
  }

  .dropdown-menu  {
        margin-left: 0px;
        transition: all 500ms cubic-bezier(.43, .26, .11, .99);
        -webkit-transition:all 500ms cubic-bezier(.43, .26, .11, .99);
        -o-transition:all 500ms cubic-bezier(.43, .26, .11, .99);
        -ms-transition: all 500ms cubic-bezier(.43, .26, .11, .99);
        width: 250px;
        height:auto;
        padding: 0px;
        border-radius: 0px;
        border: 2px solid rgba(0, 0, 0, 0.03);
        color:$white;
        background-color: $black;
        -webkit-transform: translateY(0px);
           -moz-transform: translateY(0px);
            -ms-transform: translateY(0px);
             -o-transform: translateY(0px);
                transform: translateY(0px);
        a{
          font-weight: 400;
          color:$white;
          background:$black;
          padding:15px;
          min-width: 210px;
          line-height:20px;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;
          padding: 12px 20px;

          &:hover{
            background:#111;
            color: $white;
            padding-left: 20px;
          }
        }
    }
}


.main-menu {
    padding: 70px 0 150px;
}

.header-social-wrapper{
    position: absolute;
    bottom: 20px;
    display: inline-block;
    margin: 10px 0 0;
    z-index: 100;
}


.header-social-wrapper{
  ul li a{
    font-size: 14px;
    padding: 0px 10px;
    width: 40px;
    height:40px;
    margin-bottom: 5px;
    text-align: center;
    background:rgba(0,0,0,0.05);
    display: inline-block;
    padding-top: 6px;
    color: rgba(0,0,0,0.7);

    &:hover{
      background: $primary-color;
      color: $white;
    }
  }
}


// ------------------

.header-top{
  position: relative!important;
}


.navigation-dark{
  .navbar-toggler span{
    color: $white;
  }
}

.navigation-2.navigation{
  .nav-link{
    padding: 25px 8px;
  }
}



.dark-header-socials{
  text-align: left;
  a {
    color: $white;
    padding: 0px 10px;

    &:hover{
      color: $primary-color;
    }
  }
}




@include mobile-xs{
  .header-left {
    text-align: right;
    position: absolute;
    right: 0;
    z-index: 10001;
    height: 50px;
    overflow: hidden;
    top:13px;
  }

  .navigation .menu li{
    padding-left: 0px;
  }
  .dropdown-menu{
    display: none;
  }
  .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  .navigation-2.navigation .nav-link {
      padding: 15px 8px;
  }
   .navbar-nav .nav-link{
    padding: 16px 0px;
  }

  .content{
    width: 100%;
    padding: 30px;
  }
}

@include mobile{
  .header-left {
    text-align: right;
    position: absolute;
    right:20px;
    z-index: 10001;
    height: 50px;
    overflow: hidden;
    top:13px;
  }
 
  .navigation .menu li{
    padding-left: 0px;
  }
  .dropdown-menu{
    display: none;
  }
  .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .navigation-2.navigation .nav-link {
      padding: 15px 8px;
  }
   .navbar-nav .nav-link{
    padding: 16px 0px;
  }
  .content{
    width: 100%;
    padding: 30px;
  }
}

@include tablet{
  .header-left {
    text-align: right;
    position: absolute;
    right: 20px;
    z-index: 10001;
    height: 50px;
    overflow: hidden;
    top:13px;
  }

  .navigation .menu li{
    padding-left: 0px;
  }
  .dropdown-menu{
    display: none;
  }
  .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .navigation-2.navigation .nav-link {
      padding: 15px 8px;
  }
  .navbar-nav .nav-link{
    padding: 16px 0px;
  }
  .content{
    width: 100%;
    padding: 30px;
  }
}

@include desktop{
  .header-left {
    text-align: right;
    position: absolute;
    right: 20px;
    z-index: 10001;
    height: 50px;
    overflow: hidden;
    top:13px;
  }
  .navigation .menu li{
    padding-left: 0px;
  }
  .dropdown-menu{
    display: none;
  }
  .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .navigation-2.navigation .nav-link {
      padding: 15px 8px;
  }
  .navbar-nav .nav-link{
    padding: 16px 0px;
  }
  .content{
    width: 100%;
    padding: 30px;
  }
  
}