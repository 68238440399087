.instagram-post{
	position: relative;
	cursor: pointer;

	&:before{
		position: absolute;
		content:"";
		left: 0px;
		top:0px;
		width: 100%;
		height:100%;
		background: rgba(0,0,0,0.5);
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
	}

	.intsa-meta{
		position: absolute;
		content: "";
		left:0px;
		top: 50%;
		right: 0px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
	}

	span{
		width: 40px;
		height:40px;
		background: $white;
		display: inline-block;
		text-align: center;
		border-radius: 100%;
		margin: 0px 5px;
		padding-top: 8px;
		font-weight: 700;
		color: $black;
	}


	&:hover:before{
		opacity: 1;
		visibility: visible;
	}

	&:hover .intsa-meta{
		opacity: 1;
		visibility: visible;
	}
}

.breadcrumb-wrapper{
	margin-top: 4.5rem;
}